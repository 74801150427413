<template>
<div>
  <el-tabs v-model="activeName" style="margin-left: 20px">
    <el-tab-pane :label="`单月利润分析`" name="all">
      <header class="head">
        <span><b>部门组：</b>{{ dataInfo.group_name || '-' }}</span>
        <span><b>组长： </b>{{ dataInfo.leader_name || '-' }}</span>
        <span><b>总监： </b>{{ dataInfo.majordomo_name || '-' }}</span>
        <span><b>时间： </b>{{ projectDate.slice(0, 7) }}</span>
      </header>
      <el-table :data="colData" ref="multipleTable"
                default-expand-all
                row-key="value"
                :tree-props="{children: 'children'}"
                v-loading="tableLoading" border>
        <el-table-column align="center" label="红人昵称" width="140"
                         show-overflow-tooltip prop="name"></el-table-column>
        <el-table-column align="center" :label="item.nickname" min-width="100" v-for="item in dataList"
                         :key="item.id" width="140"
                         show-overflow-tooltip>
          <template slot='header'>
            <el-link @click="detailPage(item.artist_id)" size="mini" type="primary"
                     v-if="userPermissions.indexOf('kol-analysis-item-list') !== -1">
              <span>{{ item.nickname }} </span>
            </el-link>
            <span v-else>{{ item.nickname }}</span>
          </template>
          <template slot-scope="{row}">
            <span v-if="row.value==='coop_duration'">
              {{ item[row.value] }}个月
            </span>
            <span v-else>
              {{ moneyFormat(item[row.value]) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label='合计' width='180' align="right">
          <span slot-scope='{ row }'>
           {{ moneyFormat(calcRow(row)) }}
          </span>
        </el-table-column>
      </el-table>
      <line-echarts :dataList="dataList"></line-echarts>
    </el-tab-pane>
    <el-tab-pane label="按年查看" name="year">
      <team-year :dataInfo="dataInfo"></team-year>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TeamYear from '@/pages/kolModel/components/TeamYear'
import LineEcharts from '@/pages/kolModel/components/Line.vue'

export default {
  name: 'TeamList',
  data() {
    return {
      colData: [
        {
          value: 'coop_duration',
          name: '合作时长',
          width: 100,
          style: 'dark'
        },
        {
          value: 'income_total',
          name: '收入合计',
          width: 100,
          children: [
            {
              value: 'stage_total',
              name: '保量收入',
              width: 100,
            },
            {
              value: 'biz_total',
              name: '商务营收',
              width: 100,
            },
            {
              value: 'live_commission_total',
              name: '直播佣金',
              width: 100
            },
            {
              value: 'short_video_total',
              name: '短视频收入',
              width: 100
            },
            {
              id: 7,
              value: 'live_position',
              name: '直播坑位费',
              width: 100,
              children: []
            },
          ]
        },
        {
          value: 'cost_total',
          name: '费用合计',
          width: 100,
          children: [

            {
              value: 'kol_divide_total',
              name: '网红分成',
              width: 100
            },
            {
              value: 'promotion_total',
              name: 'MCN推广',
              width: 100
            },
            {
              value: 'live_put_total',
              name: '直播投放金',
              width: 100
            },
            {
              value: 'expenses_total',
              name: '报销',
              width: 100
            },
            {
              value: 'fixed_total',
              name: '预估固定费用',
              width: 100
            }
            ,
            {
              value: 'labor_cost',
              name: '人工成本',
              width: 100
            },
            {
              value: 'biz_supp_team_total',
              name: '商务支持团队',
              width: 100
            },
            {
              value: 'rebate_total',
              name: '返点费用',
              width: 100
            },
            {
              value: 'office_rr_total',
              name: '办公租金装修费',
              width: 100
            },
            {
              value: 'manage_service_fee',
              name: '管理服务费',
              width: 100
            },
          ]
        },
        {
          value: 'corp_income_tax',
          name: '企业所得税',
          width: 100
        },
        {
          value: 'net_profits',
          name: '净利润',
          width: 100
        }

      ],
      dataList: [],
      tableLoading: true,
      projectDate: '',
      deptId: '',
      activeName: 'all',
      dataYearList: [],
      dataInfo: {}
    }
  },
  components: {
    TeamYear, LineEcharts
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    ///其他数据计算
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    calcRow(row) {
      let colValue = row.value
      let cont = 0
      this.dataList.forEach((item) => {
        if (!window.isNaN(item[colValue])) {
          cont = Number(cont) + Number(item[colValue])
        }
      })
      return cont
    },
    async getList() {
      this.tableLoading = false
      this.dataList = await this.$api.modelGroupDetail({ dept_id: this.deptId, month: this.projectDate })
      this.dataInfo = await this.$api.modelGetTeamInfo({ group_id: this.deptId, month: this.projectDate })
      this.tableLoading = false
      // modelGetTeamInfo
    },
    // tableRowClassName({ row }) {
    //   if (['cost_total', 'income_total', 'profit','fans_total'].includes(row.value)) {
    //     return 'dark-green'
    //   } else return 'light-green'
    // },
    detailPage(row) {
      const { href } = this.$router.resolve({
        name: 'modelDetail',
        params: { id: row },
        query: {
          year: this.projectDate.slice(0, 4),
          month: this.projectDate.slice(5, 7)
        }
      })
      window.open(href, '_blank')
    }
  },
  mounted() {
    this.deptId = this.$route.params.id
    this.projectDate = this.$route.query.month
    this.getList()

  }
}
</script>

<style lang="scss" scoped>
.head {
  padding: 20px 0;
  font-size: 16px;

  > span {
    display: inline-block;
    margin-right: 20px;
  }
}


.el-table .dark-green {
  font-weight: bold !important;
  font-size: 18px !important;
  background: #5ca58d !important;
  color: black !important;
}
</style>
